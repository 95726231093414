import themeConfig from '../../../../themeConfig';

/**
 * Common charts and analytics data and functionalities
 *
 * @module views/charts-and-maps/charts/commonChartsAndAnalytics
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Function} fetchDataFunction - Function to fetch chart data
 * @vue-prop {Object} seriesData - Series data
 * @vue-prop {Object[]} seriesInfo - Series information
 * @vue-prop {string} noDataTitle - Text to show as title on no data
 * @vue-prop {string} noDataSubtitle - Text to show as subtitle on no data
 * @vue-data {Object} commonChartsNoDataOptions - common charts no data options
 * @vue-data {any} chartAnalyticsData - charts analytics data
 * @vue-data {number} chartKey - chart key
 */
export default {
  props: {
    fetchDataFunction: {
      type: Function,
    },
    seriesData: {
      type: Object,
    },
    seriesInfo: {
      type: Array,
      required: true,
      validator(seriesInfo) {
        return seriesInfo.every(
          (s) => typeof s === 'object'
            && 'name' in s
            && 'key' in s
            && 'color' in s,
        );
      },
    },
    noDataTitle: {
      type: String,
      required: true,
    },
    noDataSubtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      commonChartsNoDataOptions: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        title: {
          text: '',
          align: 'center',
          offsetY: 150,
          style: {
            fontSize: '1.3rem',
            color: themeConfig.colors.content,
          },
        },
        subtitle: {
          text: '',
          align: 'center',
          offsetY: 180,
          style: {
            fontSize: '1rem',
            color: themeConfig.colors.content,
          },
        },
        colors: [
          themeConfig.colors['grey-light'],
        ],
        tooltip: {
          enabled: false,
        },
      },
      chartAnalyticsData: null,
      chartKey: 0,
    };
  },
  watch: {
    chartOptions() {
      this.chartKey += 1;
    },
    chartAnalyticsData(data) {
      this.$emit('chart-data', data);
    },
  },
};
